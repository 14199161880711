import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Container,
  StyledInput,
  StyledButton,
  Text,
} from './modal.css';
import Type from 'components/type';

import VisuallyHidden from '@reach/visually-hidden';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import '@reach/dialog/styles.css';
import CloseIcon from 'images/close.svg';

const url =
  '//sharptype.us11.list-manage.com/subscribe/post?u=37b1099c2f4aaaa8e5bf140c7&amp;id=856056dd68';
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '1.125rem',
      }}
    >
      <div style={{ textTransform: 'initial' }}>
        {status === 'sending' && (
          <Type align="center">
            <div style={{ marginBottom: '1rem' }}>sending...</div>
          </Type>
        )}
        {status === 'error' && (
          <Type align="center">
            <div
              style={{ color: 'red', marginBottom: '1rem' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Type>
        )}
        {status === 'success' && (
          <Type align="center">
            <div
              style={{ marginBottom: '1rem' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Type>
        )}
      </div>
      <StyledInput
        ref={node => (email = node)}
        type="email"
        placeholder="Enter Your email address"
      />
      <StyledButton onClick={submit}>Send</StyledButton>
    </div>
  );
};
// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
export default class Modal extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    // if (key === 'Escape') {
    //   this.props.open && this.props.hideModal();
    // }
  };

  disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    const { children, open, showModal, hideModal } = this.props;

    if (typeof document !== 'undefined') {
      this.disableScrolling(open);
    }

    return (
      <Container open={open}>
        {open ? (
          <Text>Sign up for submission deadline</Text>
        ) : (
          <Text>Subscribe to our newsletter</Text>
        )}
        {open ? (
          <>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
            <Close onClick={hideModal}>
              <VisuallyHidden>Close</VisuallyHidden>
              <CloseIcon />
            </Close>
          </>
        ) : (
          <Button onClick={showModal}>Show Modal</Button>
        )}
      </Container>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
};
