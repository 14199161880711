import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
// import { toLower } from 'lodash';

const getDirection = (direction, pose) => {
  switch (direction) {
    case 'toLeft':
      return pose;
    case 'toRight':
      return pose + 'Right';
    case 'toBoth':
      return pose + 'Both';
    case 'typeToLeft':
      return pose + 'Both';
    case 'typeToRight':
      return pose + 'Both';
    default:
      return pose;
  }
};

class Transition extends PureComponent {
  render() {
    const { children, location } = this.props;

    const RoutesContainer = posed.div({
      preEnter: {
        x: 'calc(100vw - 3.5rem)',
      },
      preEnterRight: {
        x: 'calc(-100vw + 3.5rem)',
      },
      preEnterBoth: {
        x: '0vw',
      },
      enter: {
        applyAtStart: {
          position: 'fixed',
          top: 0,
          borderLeft: '1px solid',
          overflow: 'hidden',
        },
        applyAtEnd: {
          position: 'initial',
        },
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
      enterRight: {
        applyAtStart: {
          position: 'fixed',
          top: 0,
          borderRight: '1px solid',
          overflow: 'hidden',
        },
        applyAtEnd: {
          position: 'initial',
        },
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
      enterBoth: {
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
      exit: {
        applyAtStart: { overflow: 'hidden' },
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
      exitRight: {
        applyAtStart: { overflow: 'hidden' },
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
      exitBoth: {
        x: '0vw',
        transition: {
          duration: 600,
          ease: 'easeInOut',
        },
      },
    });

    location.state && location.state.direction && console.log('test');
    // To enable page transitions on mount / initial load,
    // use the prop `animateOnMount={true}` on `PoseGroup`.
    return (
      <PoseGroup
        preEnterPose={getDirection(
          (location.state && location.state.direction) || null,
          'preEnter'
        )}
        enterPose={getDirection(
          (location.state && location.state.direction) || null,
          'enter'
        )}
        exitPose={getDirection(
          (location.state && location.state.direction) || null,
          'exit'
        )}
        enterAfterExit="false"
      >
        <RoutesContainer key={location.pathname}>{children}</RoutesContainer>
      </PoseGroup>
    );
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Transition;
