// import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';
import nanostyled from 'nanostyled';

export const Text = nanostyled('div', {
  size: 'text-sm lg:text-base xl:text-lg leading-snug',
  align: null,
  spacing: null,
  base: '',
});

// export const Text = styled.div`
//   /* display: block; */
//   font-size: ${({ size }) => () => {
//     switch (size) {
//       case 'large':
//         return '1.5rem';
//       default:
//         return '1rem';
//     }
//   }};
//   line-height: 1.4;
//   ${({ align }) => align && `text-align: ${align}`};
//   ${({ font }) =>
//     font && font === 'serif' && 'font-family: "Malee Serif", serif;'};
//   ${tw`${props => props.tail}`};
//   a {
//     color: inherit;
//   }

//   h2 {
//     ${tw`mb-8`};
//   }

//   h3 {
//     ${tw`mb-4`};
//   }

//   ul {
//     padding: 0 4rem;
//     column-count: 2;
//     column-gap: 4rem;
//     list-style-type: disc;
//     margin-left: 1em;
//     margin-bottom: 6rem;

//     li {
//       margin-bottom: 0.25em;
//     }
//     /* li::before {
//       content: "●";
//       padding-right: 1em;
//     } */
//   }

//   ${MEDIA.DESKTOP`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.25rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

//   ${MEDIA.TABLET`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.5rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

//   ${MEDIA.XLPHONE`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.25rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

// `;
