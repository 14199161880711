import React from 'react';
import PropTypes from 'prop-types';
import 'focus-visible/dist/focus-visible.js';

import Transition from 'components/transition';
import Layout from 'components/layout';

const wrapPageElement = ({ element, props }) => {
  return (
    <Layout>
      <Transition {...props}>{element}</Transition>
    </Layout>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.any,
  props: PropTypes.any,
};

export default wrapPageElement;
