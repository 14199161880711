import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import slugify from 'slugify';
import { Location } from '@reach/router';
import posed, { PoseGroup } from 'react-pose';
import { Consumer } from 'store/createContext';

import Modal from 'containers/modal';
import AppNav from 'containers/app-nav';
import Head from 'components/head';
import Header from 'components/header';
import { info, type } from 'constants/theme';

import GlobalStyle from 'global.css.js';
import 'styles.css';

import { Navigation, Left, Right, LinkItem } from './layout.css';

const Animated = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
});

const Layout = ({ data, location, children }) => (
  <div>
    <GlobalStyle />
    <Head />
    <Header title={data.site.siteMetadata.siteTitle} />
    <Navigation>
      <Left>
        <Consumer>
          {({ resetSlides }) => (
            <Link
              to={'/type'}
              onClick={resetSlides}
              activeStyle={{ background: type, pointerEvents: 'none' }}
              state={{ direction: 'toRight' }}
            >
              <div>
                <LinkItem rotate="-90deg">Women of Type</LinkItem>
              </div>
            </Link>
          )}
        </Consumer>
      </Left>
      <Right>
        <Link
          to="/about"
          activeStyle={{ background: info, pointerEvents: 'none' }}
          state={{ direction: 'toLeft' }}
        >
          <LinkItem rotate="90deg">About</LinkItem>
        </Link>
        <Link
          to="/application"
          activeStyle={{ background: info, pointerEvents: 'none' }}
          state={{ direction: 'toLeft' }}
        >
          <LinkItem rotate="90deg">Application</LinkItem>
        </Link>
        <Link
          to="/recognition"
          activeStyle={{ background: info, pointerEvents: 'none' }}
          state={{ direction: 'toLeft' }}
        >
          <LinkItem rotate="90deg">Recognition</LinkItem>
        </Link>
        {/* <Link
          to="/donations"
          activeStyle={{ background: info, pointerEvents: 'none' }}
          state={{ direction: 'toLeft' }}
        >
          <LinkItem rotate="90deg">Donations</LinkItem>
        </Link> */}
        <Link
          to="/donations"
          activeStyle={{ background: info, pointerEvents: 'none' }}
          state={{ direction: 'toLeft' }}
        >
          <LinkItem rotate="90deg">Donations</LinkItem>
        </Link>
      </Right>
    </Navigation>
    {children}
    <PoseGroup enterAfterExit="false">
      {location.pathname === '/' && (
        <Animated key="modal">
          <Modal />
        </Animated>
      )}
    </PoseGroup>
    <AppNav
      show={
        location.pathname === '/application-form' ||
        location.pathname === '/application-form/'
      }
    />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
        contentfulTypeSlide {
          title
        }
      }
    `}
    render={data => (
      <Location>
        {({ location }) => (
          <Layout data={data} {...props} location={location} />
        )}
      </Location>
    )}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
