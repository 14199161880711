import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import { Portal } from 'react-portal';
import posed, { PoseGroup } from 'react-pose';

import AppNav from 'components/app-nav';

const Animated = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 },
});

const AppNavContainer = ({ show }) => (
  <Consumer>
    {({ currentApplicationStep, openResults, hideResults }) => (
      <Portal>
        <PoseGroup enterAfterExit="false">
          {show && (
            <Animated key="appnav">
              <AppNav
                step={currentApplicationStep}
                openResults={openResults}
                hideResults={hideResults}
              />
            </Animated>
          )}
        </PoseGroup>
      </Portal>
    )}
  </Consumer>
);

AppNavContainer.propTypes = {
  show: PropTypes.bool,
};

export default AppNavContainer;
