import styled from 'styled-components';
import { Link } from 'gatsby';
import { type, typeBright, infoBright, info } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Navigation = styled.nav`
  outline: 1px solid;
  font-family: 'Malee Serif', serif;
  text-transform: uppercase;
  position: fixed;
  top: 3.5rem;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
  box-sizing: initial;
  ${MEDIA.XLPHONE`
    font-size: 0.875rem;
    top: 2.25rem;
  `}
  @media (max-height: 640px) {
    font-size: 0.875rem;
    top: 2.25rem;
  }
`;

export const Left = styled.div`
  background: ${typeBright};
  width: 3.5rem;
  height: 100%;
  border-right: 1px solid black;
  pointer-events: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  ${MEDIA.XLPHONE`
    width: 2.25rem;
  `}
  @media (max-height: 640px) {
    width: 2.25rem;
  }
  a {
    background-color: ${typeBright};
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    transition: 0.2s background-color ease;
    &:not(:last-of-type) {
      border-bottom: 1px solid black;
    }
    &:hover {
      background-color: ${type};
    }
  }
`;

export const Right = styled.div`
  width: 3.5rem;
  height: 100%;
  border-left: 1px solid black;
  pointer-events: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  ${MEDIA.XLPHONE`
    width: 2.25rem;
  `}
  @media (max-height: 640px) {
    width: 2.25rem;
  }
  a {
    background-color: ${infoBright};
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    transition: 0.2s background-color ease;
    &:not(:last-of-type) {
      border-bottom: 1px solid black;
    }
    &:hover {
      background-color: ${info};
    }
  }
`;

export const LinkItem = styled.span`
  color: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%) rotate(${({ rotate }) => `${rotate}`});
`;
