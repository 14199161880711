import styled from 'styled-components';
import { infoBright, typeBright } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  font-family: 'Malee Serif', serif;
  line-height: 1;
  text-transform: uppercase;
  position: fixed;
  border: 1px solid;
  background: white;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 1.25rem;
  bottom: 1.25rem;
  width: 20rem;
  transition: color 0.2s ease;
  ${({ open }) =>
    !open &&
    `
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  `}
  ${MEDIA.XLPHONE`
    left: 2.25rem;
    right: 2.25rem;
    bottom: 0;
    border-bottom: 0;
    transform: none;
    width: auto;
    font-size: 0.875rem;
    ${({ open }) =>
      !open &&
      `
      padding: 0.75rem 1rem;
    `}
  `}
  @media (max-height: 640px) {
    left: 2.25rem;
    right: 2.25rem;
    bottom: 0;
    border-bottom: 0;
    transform: none;
    width: auto;
    font-size: 0.875rem;
    ${({ open }) =>
      !open &&
      `
      padding: 0.75rem 1rem;
    `}
  }
`;

export const Text = styled.span`
  display: block;
  text-align: center;
  line-height: 1.2;
`;

export const Button = styled.button`
  -webkit-appearance: none;
  opacity: 0;
  background-color: transparent;
  color: transparent;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  &:focus {
    opacity: 1;
  }

  /*
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
    outline: none;
  } */
`;

export const StyledInput = styled.input`
  background: ${infoBright};
  border: 1px solid;
  font-size: 1rem;
  width: 100%;
  padding: 1rem;
  font-family: 'Malee Serif', serif;
  &::placeholder {
    color: black;
    text-transform: uppercase;
  }
`;

export const StyledButton = styled.button`
  -webkit-appearance: none;
  background-color: ${typeBright};
  border: 1px solid;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  margin-top: 1.25rem;
  font-family: 'Malee Serif', serif;
  text-transform: uppercase;
  transition: color 0.2s ease;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid;
  border-radius: 100%;
  font-family: inherit;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0;
  height: 2.875rem;
  width: 2.875rem;
  color: #000;
  padding: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  transition: ease 0.25s;
  &:hover {
    transform: translate(50%, -50%) rotate(90deg);
  }
`;
