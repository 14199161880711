import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Title, StyledSvg } from './header.css';
import VisuallyHidden from '@reach/visually-hidden';

import Logo from 'images/malee-logo-revised.svg';

const Header = ({ title }) => (
  <Container>
    <Link
      to="/"
      activeStyle={{ pointerEvents: 'none' }}
      state={{ direction: 'toBoth' }}
    >
      <VisuallyHidden>
        <Title>{title}</Title>
      </VisuallyHidden>
      <StyledSvg>
        <Logo />
      </StyledSvg>
    </Link>
  </Container>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
