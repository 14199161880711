import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

//// fix scrolling for animation
//const transitionDelay = 600;
//export const shouldUpdateScroll = ({ pathname }) => {
//  // // We use a temporary hack here, see #7758
//  console.log('test');
//  if (window.__navigatingToLink) {
//    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
//  } else {
//    const savedPosition = JSON.parse(
//      window.sessionStorage.getItem(`@@scroll|${pathname}`)
//    );
//    window.setTimeout(() => window.scrollTo(...savedPosition), transitionDelay);
//  }
//  return false;
//}
//;
const transitionDelay = 600;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};
