import React from 'react';
import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';

import { Container, Group, Item } from './app-nav.css';

const AppNav = ({ width, flex, step, openResults, hideResults }) => (
  <Container
    className="app-nav"
    width={width}
    flex={flex}
    style={{ zIndex: 999 }}
  >
    {!openResults ? (
      <Group>
        <ScrollLink
          to="step1"
          offset={-200}
          smooth={true}
          duration={250}
          style={{ width: '100%' }}
        >
          <Item
            border="border-r"
            active={step == 'step1' ? 'bg-info' : 'bg-infoBright'}
            style={{ fontVariantNumeric: 'lining-nums' }}
          >
            Step 1
          </Item>
        </ScrollLink>
        <ScrollLink
          to="step2"
          offset={-200}
          smooth={true}
          duration={250}
          style={{ width: '100%' }}
        >
          <Item
            border="border-r"
            active={step == 'step2' ? 'bg-info' : 'bg-infoBright'}
            style={{ fontVariantNumeric: 'lining-nums' }}
          >
            Step 2
          </Item>
        </ScrollLink>

        <ScrollLink
          to="step3"
          offset={-200}
          smooth={true}
          duration={250}
          style={{ width: '100%' }}
        >
          <Item
            border="border-r"
            active={step == 'step3' ? 'bg-info' : 'bg-infoBright'}
            style={{ fontVariantNumeric: 'lining-nums' }}
          >
            Step 3
          </Item>
        </ScrollLink>
        <ScrollLink
          to="step4"
          offset={-200}
          smooth={true}
          duration={250}
          style={{ width: '100%' }}
        >
          <Item
            active={step == 'step4' ? 'bg-info' : 'bg-infoBright'}
            style={{ fontVariantNumeric: 'lining-nums' }}
          >
            Step 4
          </Item>
        </ScrollLink>
      </Group>
    ) : (
      <Group>
        <Item onClick={hideResults}>Back to Application</Item>
      </Group>
    )}
  </Container>
);

AppNav.propTypes = {
  width: PropTypes.string,
  flex: PropTypes.string,
};

export default AppNav;
