import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    open: false,
    openResults: false,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    showResults: () => this.setState({ openResults: true }),
    hideResults: () => this.setState({ openResults: false }),
    activeSlide: 0,
    slideDirection: null,
    prevSlide: () =>
      this.setState({
        activeSlide: this.state.activeSlide - 1,
        slideDirection: 'prev',
      }),
    nextSlide: () =>
      this.setState({
        activeSlide: this.state.activeSlide + 1,
        slideDirection: 'next',
      }),
    goToSlide: (index, direction) =>
      this.setState({ activeSlide: index, slideDirection: direction }),
    resetSlides: () => this.setState({ activeSlide: 0 }),
    currentApplicationStep: '',
    changeApplicationStep: step =>
      this.setState({ currentApplicationStep: step }),
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
